import {store} from "../../../store";
import {GameActionCreators} from "../../../store/reducers/game/action-creators";
import { SoundsService } from "../../SoundsService";
import { sounds } from "../../../sounds/sound";

export default class Table{
    static async set(side, serverState, dispatch, tableKey = null ){

        let soundPlayUnit = false;
        let soundDeadUnit = false;
        let card = null;

        let state = store.getState();
        let table = state.game[side + 'Table'];

        if(tableKey !== null){
            if(serverState === 0 && table[tableKey] !== 0){
           
                soundDeadUnit = true;
            }
            table[tableKey] = serverState;
        }else{
            for (let i = 0; i < 5; i++) {
                if (serverState[side].table[i] !== undefined) {

                 if(side === 'cpu' && table[i] === 0 && serverState[side].table[i] !== 0){
                    soundPlayUnit = true;
                    card = serverState[side].table[i].card;
                 }

                 if(table[i]!== null && table[i] !== 0 && serverState[side].table[i] === 0){
                
                    soundDeadUnit = true;
                 }

                table[i] = serverState[side].table[i];

                }
            }
        }
        if(soundPlayUnit === true){
            await new Promise(r => setTimeout(r, 500));
        }

        dispatch(GameActionCreators['set'+ side.replace(/^./, str => str.toUpperCase()) +'Table'](table));

        if(soundDeadUnit === true){
            sounds.battle_unit_dead().play();
            await new Promise(r => setTimeout(r, 400));
        }

       if(soundPlayUnit === true){
         SoundsService.playCard(card);
         await new Promise(r => setTimeout(r, 700));
       }

    }
}