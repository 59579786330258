import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import UserService from '../../../api/UserService';
import { useEffect } from 'react';

function ClickShop100() {

  useEffect(() => {
    UserService.sendEvent('ClickShop100');
  }, []);

    return (
        <div>
            <img className='MenuFoxHead' src={FoxHead}/>
            <div className='MenuFoxHeadContainer HeadTextContainer'>
                <div className='HeadTextField'>Press BUY button</div>
            </div>
            <div className='ClickResearch100Top'>

            </div>

            <div className='ClickResearch100Right'></div>
            <div className='ClickResearch100Bottom'>
                <img className='ClickResearch100RedArrow' src={RedArrow}/>
            </div>

        </div>

    );
}

export default ClickShop100;